import { fetchAny, fetchJSON } from './fetch'

type Buffer = {
  "type": "Buffer",
  "data": number[]
}

export type UserData = {
  id: number,
  login: string,
  name: string,
  description?: Buffer,
  affiliate: boolean,
  partner: boolean,
  avatar: string,
  gameid?: number,
  title?: Buffer,
  online?: 0 | 1,
  tags: string
  thumbnail_url?: string
  viewer_count?: number
  commands?: string
}

export const getConfiguration = () => {
  return fetchJSON.get('/settings') // eslint-disable-line
}

export const getStreams = () => {
  return fetchJSON.get('/streams') // eslint-disable-line
}

export const getTeamStreams = (team: string) => {
  return fetchJSON.get('/team', { team }) // eslint-disable-line
}

export const getCommands = (name: string): Promise<UserData> => {
  return fetchJSON.get(`/chatbot/commands/${name.trim().toLowerCase()}`)
}

export const getTags = () => {
  return fetchJSON.get('/tags') // eslint-disable-line
}

export const getUserData = () => {
  return fetchJSON.get(`/user`) // eslint-disable-line
}

export const logOut = () => {
  return fetchAny.get(`/logout`) // eslint-disable-line
}

export const getPreferences = () => {
  return fetchJSON.get(`/preferences`) // eslint-disable-line
}

export const setPreferences = (prefs?: any) => {
  return fetchJSON.post(`/preferences`, prefs) // eslint-disable-line
}

const API = {
  getCommands
}

export default API