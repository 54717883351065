import React from 'react'
import { TabBar } from '../TabBar'
import styles from './styles.module.scss'
import logo from './assets/logo.png'
import { RainbowText } from '../RainbowText'

export const AppBar: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className='container'>
        <div className={styles.content}>
          <div className={styles.logo}>
            <img src={logo} alt='Draco.Bot Logo' />
            <span className='h5'>
              <RainbowText reverse text='Draco.Bot' />
            </span>
          </div>
          <TabBar
            className={styles.tabs}
            tabs={[
              { caption: 'Home', location: '/chatbot' },
              { caption: 'Features', location: '/chatbot/features' },
              { caption: 'FAQs', location: '/chatbot/faqs' },
              { caption: 'Examples', location: '/chatbot/examples' },
            ]}
          />
        </div>
      </div>
      <div className={styles.barBigBlur} />
      <div className={styles.barBlur} />
      <div className={styles.bar} />
    </div>
  )
}

export default AppBar
