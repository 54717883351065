import classNames from 'classnames'
import React from 'react'
import styles from './styles.module.scss'
import logo from '../AppBar/assets/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faTwitch } from '@fortawesome/free-brands-svg-icons'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

type Props = {
  className?: string
}

export const AppFooter: React.FC<Props> = ({ className }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={classNames(styles.content, 'container')}>
        <div className={styles.logo}>
          <img src={logo} alt='Draco.Bot Logo' />
          <span className='h6'>Draco.Bot</span>
        </div>
        <div className={styles.copyright}>&copy; 2023 Draconem Labs. All Rights Reserved</div>
        <div className={styles.links}>
          <a href='https://discord.gg/5s2rhp8qBq' target='_blank' rel='noreferrer'>
            <FontAwesomeIcon icon={faDiscord as IconDefinition} className={styles.icon} />
          </a>
          <a href='https://www.twitch.tv/SilenceDraconem/' target='_blank' rel='noreferrer'>
            <FontAwesomeIcon icon={faTwitch as IconDefinition} className={styles.icon} />
          </a>
        </div>
      </div>
    </div>
  )
}
