import * as React from 'react'
import { ifElse } from '../../utilities'
import { RainbowText } from '../RainbowText'
import styles from './styles.module.scss'
import TwoColumn from '../TwoColumn'
import classNames from 'classnames'

type Props = React.PropsWithChildren<{
  className?: string
  icon?: React.ReactNode
  title?: string | React.ReactNode
  id?: string
}>

const Section: React.FC<Props> = ({ id, className, icon, title, children }) => {
  return (
    <div className={classNames(styles.container, className)}>
      {ifElse(id, <div id={id} />)}
      <div className='container'>
        <TwoColumn auto center noGap={!icon}>
          {icon ?? <div />}
          <div>
            {ifElse(
              typeof title === 'string',
              <div className={styles.title}>
                <RainbowText text={title} />
              </div>,
              title,
            )}
            {children}
          </div>
        </TwoColumn>
      </div>
    </div>
  )
}

export default Section
