import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'

type Props = {
  src?: string[]
  className?: string
  blurOut?: boolean
  transitionTime?: number
  showTime?: number
  glowAmount?: 'full' | 'small' | 'none'
  reverse?: boolean
}

export const Screenshot: React.FC<Props> = ({ src, className, transitionTime = 2000, showTime = 5000, glowAmount = 'full', reverse }) => {
  let id: NodeJS.Timeout | null = null
  const [index, setIndex] = useState((src?.length ?? 0) - 1)

  const nextIndex = (i: number) => (i + 1) % (src?.length ?? 1)
  const clearTransition = () => {
    if (id) {
      clearTimeout(id)
    }
  }

  useEffect(() => {
    clearTransition()
    if ((src?.length ?? 0) > 1) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      id = setInterval(() => {
        setIndex((i) => nextIndex(i))
      }, showTime + transitionTime)
      return clearTransition
    }
  }, [src])

  const transitionStyle = { transitionDuration: `${transitionTime}ms` }

  return src?.length ? (
    <div className={classNames(styles.container, reverse && styles.reverse)}>
      <div
        className={classNames(
          styles.screenshot,
          className,
          { [styles.fullGlow]: glowAmount === 'full' },
          { [styles.smallGlow]: glowAmount === 'small' },
          { [styles.noGlow]: !glowAmount || glowAmount === 'none' },
        )}
      >
        <div className={classNames(styles.imageContainer, styles.sizer)}>
          <img src={src[index]} alt='Screenshot' />
        </div>
        {src.map((image, i) => (
          <div
            key={`Screenshot_${i}`}
            className={classNames(styles.imageContainer, {
              [styles.previous]: i === index,
              [styles.current]: i === nextIndex(index),
            })}
            style={i === index || i === nextIndex(index) ? transitionStyle : undefined}
          >
            <img src={image} className={styles.blur} alt='' />
            <img src={image} alt='' />
            {/* {src?.length > 1 ? null : (
              <div className={styles.reflectionHolder}>
                <img src={image} className={styles.reflection} />
              </div>
            )} */}
            <div className={styles.reflectionHolder}>
              <img src={image} className={styles.reflection} alt='' />
            </div>
          </div>
        ))}
      </div>
    </div>
  ) : null
}
