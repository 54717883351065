import classNames from 'classnames'
import React from 'react'
import styles from './styles.module.scss'

type Props = {
  className?: string
  selected?: boolean
  caption: string
  location: string
}

export const Tab: React.FC<Props> = ({ className, caption, selected, location }) => {
  return (
    <a href={location} className={classNames(styles.container, className, selected && styles.selected)}>
      {caption}
    </a>
  )
}

export default Tab
