/* eslint-disable jsx-a11y/alt-text */
import * as React from 'react'
import ChatbotWrapper from '../../../components/ChatbotWrapper'
import styles from './styles.module.scss'
import TwoColumn from '../../../components/TwoColumn'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDiscord, faTwitch } from '@fortawesome/free-brands-svg-icons'
import { faMusic, faHeart } from '@fortawesome/free-solid-svg-icons'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { ReactComponent as OBSLogo } from '../assets/obs.svg'
import { ReactComponent as VTSLogo } from '../assets/vts.svg'
import Section from '../../../components/Section'
import List from '../../../components/List'

type Props = {}

const Features: React.FC<Props> = () => {
  return (
    <ChatbotWrapper
      decorate
      containerWrap={false}
      title='Features'
      titleButtons={
        <div>
          <a href='#twitch' title='Twitch Integration' className={styles.iconButton}>
            <FontAwesomeIcon icon={faTwitch as IconDefinition} />
          </a>
          <a href='#obs' title='OBS Integration' className={styles.iconButton}>
            <OBSLogo />
          </a>
          <a href='#vts' title='VTube Studio Integration' className={styles.iconButton}>
            <VTSLogo />
          </a>
          <a href='#jukebox' title='Built-in Jukebox' className={styles.iconButton}>
            <FontAwesomeIcon icon={faMusic as IconDefinition} />
          </a>
          <a href='#love' title='More Love' className={styles.iconButton}>
            <FontAwesomeIcon icon={faHeart as IconDefinition} />
          </a>
        </div>
      }
      header={
        <>
          <p>
            Draco.Bot is a labor of love, designed and written by a cohort of passionate streamers and engineers. With direct integrations with Twitch, OBS Studio, and VTube Studio, you have direct
            control over your livestream, enabling complex automated tasks.
          </p>
          <p>
            We are continually evolving Draco.Bot given user feedback, taking into account real-world use. If you find that it is missing a feature or utility that you would find helpful, please join
            our{' '}
            <a href='https://discord.gg/5s2rhp8qBq' target='_blank' rel='noreferrer'>
              Discord server <FontAwesomeIcon size='xs' icon={faDiscord as IconDefinition} />
            </a>{' '}
            and let us know!
          </p>
        </>
      }
    >
      <div className={styles.container}>
        <Section id='twitch' title='Twitch Integration' icon={<FontAwesomeIcon icon={faTwitch as IconDefinition} className={styles.icon} />}>
          <p>
            With our fully-featured Twitch integration, you have complete control over Twitch. Whether you want to respond to a specific event (Raid, Subscription, etc.) or just automate some
            messaging, we have you covered.
          </p>
          <TwoColumn className={styles.subsection}>
            <List title='Handle Twitch Events' items={['User Followed', 'User Cheered', 'User Subscribed', 'Raid', 'Chat Messages', 'Channel Point Redemption']} />
            <List
              className={styles.second}
              title='Twitch Actions'
              items={['Send Chat Message', 'Create Announcement', 'Create Shoutout', 'Toggle Emote-Only Mode', 'Toggle Follower-Only Mode', 'Toggle Subscriber-Only Mode']}
            />
          </TwoColumn>
        </Section>
        <Section id='obs' title='OBS Studio Integration' icon={<OBSLogo className={styles.icon} />}>
          <p>Take complete control of your broadcasting software. Control OBS by switching scenes, adjusting content, toggling filters, and much more.</p>
          <p>
            Draco.Bot requires <b>OBS Studio v28+</b>, which allows for seamless control over all aspects of your broadcast.
          </p>
          <List title='OBS Actions' items={['Switch Scenes', 'Toggle Source Visibility', 'Toggle Source Filters', 'Set Source Content', 'Set Source Text']} />
        </Section>
        <Section id='vts' title='VTube Studio Integration' icon={<VTSLogo className={styles.icon} />}>
          <p>Control your VTube Studio from anywhere.</p>
          <List title='VTube Studio Actions' items={['Change Models', 'Move, Rotate, and Resize Your Model', 'Toggle Expressions', 'Access Model Hotkeys']} />
        </Section>
        <Section id='jukebox' title='Built-in Jukebox' icon={<FontAwesomeIcon icon={faMusic as IconDefinition} className={styles.icon} />}>
          <p>Draco.Bot has a built-in playlist; drag and drop your audio files into the list to fill up your jukebox.</p>
          <div className={styles.subsection}>
            <h4>Curate your Music</h4>
            <p>Protect yourself by ensuring that your stream music is properly licensed. Use your own collection of free-to-use and stream-safe music on your livestreams.</p>
          </div>
          <div className={styles.subsection}>
            <h4>Music Requests</h4>
            <p>
              If you're feeling charitable, you can allow your stream chat to make music requests from your music library. If enabled, your playlist will be published to your commands page, allowing
              viewers to browse and select from the available files.
            </p>
            <p>Just in case a viewer makes a typo, Draco.Bot will attempt to select an available file that most closely matches what the user requested.</p>
          </div>
        </Section>
        <Section id='love' title='More Love' icon={<FontAwesomeIcon icon={faHeart as IconDefinition} className={styles.icon} />}>
          <div className={styles.subsection}>
            <h4>Reward Your Regulars</h4>
            <p>Increase stream engagement by giving your regulars access to special commands and greeting them when they interact with chat.</p>
            <p>All of the chat commands in Draco.Bot can be restricted to specific viewer levels (VIP, Sub, etc.) or to specific regulars.</p>
          </div>
          <div className={styles.subsection}>
            <h4>Automatic Greetings</h4>
            <p>Quickly and easily create complex automatic greetings when a specific viewer interacts with your stream.</p>
          </div>
        </Section>
      </div>
    </ChatbotWrapper>
  )
}

export default Features
