import classNames from 'classnames'
import React from 'react'
import styles from './styles.module.scss'

type Props = {
  className?: string
  reverse?: boolean
  light?: boolean
  text: React.ReactNode
}

export const RainbowText: React.FC<Props> = ({ className, text, reverse, light }) => {
  return (
    <div className={classNames(styles.container, reverse && styles.reverse, light && styles.light, className)}>
      <div className={styles.bigblur}>{text}</div>
      <div className={styles.blur}>{text}</div>
      <div className={styles.text}>{text}</div>
      <div className={styles.text}>{text}</div>
      <div className={styles.size}>{text}</div>
    </div>
  )
}
