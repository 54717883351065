import * as React from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'

type Props = {
  className?: string
  children: React.ReactNode
  auto?: boolean
  autoRight?: boolean
  wrap?: boolean
  lineitem?: boolean
  lineitemSmall?: boolean
  lineitemLarge?: boolean
  noGap?: boolean
  smallGap?: boolean
  center?: boolean
}

const getAutoWrapClass = (className: string, autoClassName: string, autoRightClassName: string, wrapClassName: string, auto?: boolean, autoRight?: boolean, wrap?: boolean) => {
  if (auto) {
    return autoClassName
  }
  if (autoRight) {
    return autoRightClassName
  }
  if (wrap) {
    return wrapClassName
  }
  return className
}

const TwoColumn = ({ className, children, auto, autoRight, wrap, lineitem, lineitemSmall, lineitemLarge, noGap, smallGap, center, ...props }: Props) => (
  <div
    className={classNames(
      getAutoWrapClass(styles.twoColumn, styles.twoColumnAuto, styles.twoColumnAutoRight, styles.twoColumnWrap, auto, autoRight, wrap),
      center && styles.centerAlign,
      lineitem && styles.lineitem,
      lineitemSmall && styles.lineitemSmall,
      lineitemLarge && styles.lineitemLarge,
      noGap && styles.noGap,
      smallGap && styles.smallGap,
      className,
    )}
    {...props}
  >
    {children}
  </div>
)

export default TwoColumn
