const API_BASE = '/api'

const fetchBuilder = (method: string, base?: string) => {
  return (url: string, data?: any) =>
    fetch(`${base}${url}`, {
      method,
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: data && JSON.stringify(data),
    }).then(response => response)
}

const getFetchBuilder = (base?: string) => {
  return (url: string, data?: any) => {
    const urlData = !data
      ? ''
      : '?' +
        Object.keys(data)
          .map(i => `${encodeURIComponent(i)}=${encodeURIComponent(data[i])}`)
          .join('&')
    return fetch(`${base}${url}${urlData}`, {
      method: 'get',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response)
  }
}

const fetchBuilderJSON = (method: string, base?: string) => {
  return (url: string, data?: any) =>
    fetch(`${base}${url}`, {
      method,
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: data && JSON.stringify(data),
    }).then(response => response.json())
}

const getFetchBuilderJSON = (base?: string) => {
  return (url: string, data?: any) => {
    const urlData = !data
      ? ''
      : '?' +
        Object.keys(data)
          .map(i => `${encodeURIComponent(i)}=${encodeURIComponent(data[i])}`)
          .join('&')
    return fetch(`${base}${url}${urlData}`, {
      method: 'get',
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(response => response.json())
  }
}

export const fetchAny = {
  // Functions without a base URL
  getBase: getFetchBuilder(),
  postBase: fetchBuilder('post'),
  putBase: fetchBuilder('put'),
  deleteBase: fetchBuilder('delete'),

  // Functions with the API_BASE as base URL
  get: getFetchBuilder(API_BASE),
  post: fetchBuilder('post', API_BASE),
  put: fetchBuilder('put', API_BASE),
  delete: fetchBuilder('delete', API_BASE),
}

export const fetchJSON = {
  // Functions without a base URL
  getBase: getFetchBuilderJSON(),
  postBase: fetchBuilderJSON('post'),
  putBase: fetchBuilderJSON('put'),
  deleteBase: fetchBuilderJSON('delete'),

  // Functions with the API_BASE as base URL
  get: getFetchBuilderJSON(API_BASE),
  post: fetchBuilderJSON('post', API_BASE),
  put: fetchBuilderJSON('put', API_BASE),
  delete: fetchBuilderJSON('delete', API_BASE),
}

export default fetchJSON
