import classNames from 'classnames'
import React from 'react'
import Tab from './Tab'
import styles from './styles.module.scss'
import { useLocation } from 'react-router'

type TabInfo = {
  caption: string
  location: string
}

type Props = {
  className?: string
  tabs?: TabInfo[]
}

export const TabBar: React.FC<Props> = ({ className, tabs }) => {
  const location = useLocation()
  return (
    <div className={classNames(styles.container, className)}>
      {tabs?.map((tab, i) => (
        <Tab key={`TAB_${i}`} {...tab} selected={tab.location === location.pathname} />
      ))}
    </div>
  )
}
