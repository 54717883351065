import React from 'react'
import { Helmet } from 'react-helmet'
import logo from './assets/draconem-labs-logo-white.png'
import styles from './styles.module.scss'

function Home() {
  return (
    <div className={styles.container}>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Draconem Labs</title>
      </Helmet>
      <div className={styles.logoContainer}>
        <img className={styles.logoBack} src={logo} alt='' />
        <img className={styles.logo} src={logo} alt='Draconem Labs' />
      </div>
    </div>
  )
}

export default Home
