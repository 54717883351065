import * as React from 'react'
import { Helmet } from 'react-helmet'
import styles from './styles.module.scss'
import classNames from 'classnames'
import AppBar from '../AppBar'
import { RainbowText } from '../RainbowText'
import { AppFooter } from '../AppFooter'
import { ifElse } from '../../utilities'

type Props = React.PropsWithChildren<{
  className?: string
  title: string
  titleButtons?: React.ReactNode
  hideTitle?: boolean
  header?: React.ReactNode
  decorate?: boolean
  containerWrap?: boolean
}>

const ChatbotWrapper: React.FC<Props> = ({ title, titleButtons, hideTitle, header, className, children, decorate, containerWrap = true }) => {
  return (
    <div>
      <Helmet>
        <meta charSet='utf-8' />
        <title>{title} | Draco.Bot</title>
      </Helmet>
      <AppBar />
      <div className={classNames(styles.container, decorate && styles.decorated)}>
        <div className={styles.header}>
          <div className='container'>
            <div className={styles.headerContainer}>
              {ifElse(
                !hideTitle,
                <h1 className={styles.title}>
                  <RainbowText text={title} />
                </h1>,
              )}
              {ifElse(titleButtons, <div className={styles.titleButtons}>{titleButtons}</div>)}
              {ifElse(header, <div className={styles.headerContent}>{header}</div>)}
            </div>
          </div>
        </div>
        {ifElse(
          decorate,
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 50' className={styles.decoration}>
            <path fillOpacity='1' d='M0,50 C100,20 550,0 800,0 L 0,0' />
          </svg>,
        )}
      </div>
      <div className={classNames(styles.container, decorate && styles.decorated, className)}>
        {ifElse(
          containerWrap,
          <div className='container'>
            <div className={styles.content}>{children}</div>
          </div>,
          <div className={styles.content}>{children}</div>,
        )}
        {ifElse(
          decorate,
          <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 50' className={classNames(styles.decoration, styles.flip)}>
            <path fillOpacity='1' d='M0,50 C100,20 550,0 800,0 L 0,0' />
          </svg>,
        )}
        <div className={styles.footer}>
          <AppFooter />
        </div>
      </div>
    </div>
  )
}

export default ChatbotWrapper
