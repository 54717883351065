import * as React from 'react'
import API, { UserData } from '../../../api'
import { useParams } from 'react-router'
import classNames from 'classnames'
import styles from './styles.module.scss'
import { ifElse } from '../../../utilities'
import CommandList from './CommandList/CommandList'
import ChatbotWrapper from '../../../components/ChatbotWrapper'

type Props = {}

type DracoBotCommand = {
  command: string
  description?: string
  minLevel?: number
}

type ParsedCommands = {
  commands?: DracoBotCommand[]
  custom?: DracoBotCommand[]
  sound?: DracoBotCommand[]
  counter?: DracoBotCommand[]
  songs?: string[]
  version?: string
}

type UserInfo = Omit<UserData, 'commands'> & { commands: ParsedCommands }

const getCommands = async (name?: string) => {
  if (!name) {
    return undefined
  }
  try {
    const response = await API.getCommands(name)
    const { commands, ...rest } = response
    let parsedCommands = {}
    try {
      parsedCommands = commands ? JSON.parse(commands) : undefined
    } catch (e) {}
    return { ...rest, commands: parsedCommands as ParsedCommands }
  } catch (e) {
    return undefined
  }
}

const CommandSets: React.FC<{ commands: ParsedCommands }> = ({ commands: { commands, counter, custom, sound, songs } }) => {
  console.log('songs', songs)
  return (
    <div className={styles.commands}>
      <CommandList title='Built-In Commands' commands={commands}></CommandList>
      <CommandList title='Custom Commands' commands={custom}></CommandList>
      <CommandList title='Sound Effect Commands' commands={sound}></CommandList>
      <CommandList title='Counter Commands' commands={counter}></CommandList>
      <CommandList title='Available Songs' commands={songs}></CommandList>
    </div>
  )
}

const Commands: React.FC<Props> = () => {
  const params = useParams()
  const [loading, setLoading] = React.useState(true)
  const [info, setInfo] = React.useState<UserInfo>()

  React.useEffect(() => {
    const getInfo = async () => {
      const userinfo = await getCommands(params.username)
      setInfo(userinfo)
      setLoading(false)
    }
    getInfo()
  }, [params.username])

  return (
    <ChatbotWrapper
      hideTitle
      decorate
      containerWrap={false}
      title={`Commands for ${info?.name ?? params.username}`}
      header={ifElse(
        !loading,
        <div className={classNames(styles.info, info?.online ? styles.online : styles.offline)}>
          <img alt='' className={styles.avatar} src={info?.avatar} />
          <div className={styles.name}>{info?.name ?? params.username}</div>
          <div className={styles.status}>{info?.online ? 'Live Now' : 'Offline'}</div>
          {!loading && info?.commands && info?.commands?.version ? <div className={styles.version}>Using {info?.commands?.version}</div> : null}
        </div>,
      )}
      className={styles.container}
    >
      {!loading && info?.commands ? <CommandSets commands={info?.commands} /> : null}
    </ChatbotWrapper>
  )
}

export default Commands
