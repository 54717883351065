import React from 'react'
import './App.scss'
import { Helmet } from 'react-helmet'
import Home from './views/Home'
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom'
import ChatbotHome from './views/Chatbot'
import ChatbotCommands from './views/Chatbot/Commands'
import ChatbotFeatures from './views/Chatbot/Features'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
    errorElement: <Navigate to='/' />,
  },
  {
    path: '/chatbot',
    element: <ChatbotHome />,
    errorElement: <Navigate to='/chatbot' />,
  },
  {
    path: '/chatbot/*',
    element: <Navigate to='/chatbot' />,
  },
  {
    path: '/chatbot/features',
    element: <ChatbotFeatures />,
  },
  {
    path: '/chatbot/commands/:username',
    element: <ChatbotCommands />,
  },
])

function App() {
  return (
    <div className='App'>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Draconem Labs</title>
      </Helmet>
      <RouterProvider router={router} />
    </div>
  )
}

export default App
