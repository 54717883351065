import classNames from 'classnames'
import React from 'react'
import styles from './styles.module.scss'

type Props = {
  className?: string
  reverse?: boolean
  children: React.ReactNode
  href?: string
  newTab?: boolean
}

export const RainbowButton: React.FC<Props> = ({ href, className, children, reverse, newTab = true }) => {
  const attr = newTab ? { target: '_blank', rel: 'noreferrer' } : undefined
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a href={href} className={classNames(styles.container, reverse && styles.reverse, className)} {...attr}>
      <div className={styles.blur} />
      <div className={styles.border} />
      <button>{children}</button>
    </a>
  )
}
