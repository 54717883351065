import React, { PropsWithChildren } from 'react'
import AppBar from '../../components/AppBar'
import { Helmet } from 'react-helmet'
import styles from './styles.module.scss'
import classNames from 'classnames'
import { RainbowText } from '../../components/RainbowText'
import ss1 from './assets/ss1.png'
import ss2 from './assets/ss2.png'
import ss3 from './assets/ss3.png'
import { Screenshot } from '../../components/Screenshot'
import { RainbowButton } from '../../components/RainbowButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition, faDownload } from '@fortawesome/free-solid-svg-icons'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import Fade from 'react-reveal/Fade'
import { ifElse } from '../../utilities'
import { useParams } from 'react-router'
import { AppFooter } from '../../components/AppFooter'

const DracoBot: React.FC<PropsWithChildren<{ reverse?: boolean; light?: boolean }>> = ({ children, reverse = true, light = true }) => {
  return <RainbowText light={light} reverse={reverse} text='Draco.Bot' />
}

type HomepageHeaderProps = {
  title: string
  subtitle: string
  reverse?: boolean
}

const HomepageHeader: React.FC<HomepageHeaderProps> = ({ title, subtitle, reverse }) => {
  return (
    <h1>
      <RainbowText text={title} reverse={reverse} />
      <small>{subtitle}</small>
    </h1>
  )
}

type HomepageSectionProps = PropsWithChildren<{
  title: string
  subtitle: string
  screenshot: string
  reverse?: boolean
}>

const HomepageSection: React.FC<HomepageSectionProps> = ({ title, subtitle, screenshot, reverse, children }) => {
  const ss = (
    <Fade key='ss' duration={1000}>
      <Screenshot className={styles.screenshot} src={[screenshot]} glowAmount='small' reverse={!reverse} />
    </Fade>
  )

  const content = (
    <Fade key='content' duration={300} distance='100px' right={!reverse} left={reverse}>
      <div className={styles.textContent}>
        <HomepageHeader {...{ title, subtitle, reverse }} />
        <div className={classNames('display-6', styles.subtitle)}>{children}</div>
      </div>
    </Fade>
  )

  return (
    <div className={classNames(styles.container, 'container')}>
      <div className={classNames(styles.content, styles.twoColumn)}>{ifElse(!reverse, [ss, content], [content, ss])}</div>
    </div>
  )
}

function ChatbotHome() {
  const params = useParams()
  console.log('*** PARAMETERS', params)
  return (
    <div>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Home | Draco.Bot</title>
      </Helmet>
      <AppBar />
      <div className={classNames(styles.container, styles.twoColumnContainer)}>
        <div className='container'>
          <div className={styles.content}>
            <div className={styles.textcontent}>
              <HomepageHeader title='Assume Direct Control' subtitle='of your livestream' reverse />
              <div className={classNames('display-6', styles.subtitle)}>
                Amplify your stream&apos;s interactivity, enhance viewer engagement, and improve software integration with <DracoBot />!
              </div>
            </div>
            <div className={styles.installButton}>
              <div className={styles.wrapper}>
                <RainbowButton reverse newTab={false}>
                  <FontAwesomeIcon icon={faDownload} className={styles.icon} />
                  <b>
                    Download <em>Draco.Bot</em>
                  </b>
                </RainbowButton>
                <div className={styles.version}>Public &Alpha;lpha Version 0.1.10</div>
              </div>
              <div className={styles.wrapper}>
                <RainbowButton href='https://discord.gg/5s2rhp8qBq'>
                  <FontAwesomeIcon icon={faDiscord as IconDefinition} className={styles.icon} />
                  <b>Join our Discord</b>
                </RainbowButton>
              </div>
            </div>
            <div className={styles.screenshots}>
              <Screenshot src={[ss1, ss2, ss3]} />
            </div>
          </div>
        </div>
      </div>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 50' className={styles.decoration}>
        <path fillOpacity='1' d='M0,50 C100,20 550,0 800,0 L 0,0' />
      </svg>
      <HomepageSection title='Simplify' subtitle='your Streaming Setup' screenshot={ss1}>
        Born from a desire for more finely-grained control over a stream, <DracoBot /> consolidates multiple applications and online services into a single piece of software with an intuitive
        interface and small footprint.
      </HomepageSection>
      <HomepageSection title='Enhance' subtitle='your viewer engagement' screenshot={ss3} reverse>
        Easily give your viewers more control over what happens during your streams; <DracoBot /> can control OBS directly from commands in your stream chat or through the use of your channel&apos;s
        points.
      </HomepageSection>
      <HomepageSection title='Automate' subtitle='your common actions' screenshot={ss2}>
        Whether it&apos;s thanking streamers who raid you, welcoming your VIPs to chat, or announcing your Discord server, <DracoBot /> can automate your stream based on all kinds of Twitch events.
      </HomepageSection>
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 50' className={classNames(styles.decoration, styles.flip)}>
        <path fillOpacity='1' d='M0,50 C100,20 550,0 800,0 L 0,0' />
      </svg>
      <div className={styles.footer}>
        <AppFooter />
      </div>
    </div>
  )
}

export default ChatbotHome
