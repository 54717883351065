import React from 'react'
import styles from './styles.module.scss'
import { ifElse } from '../../utilities'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'

type Props = {
  className?: string
  title?: string
  items: React.ReactNode[]
  more?: boolean
}

export const List: React.FC<Props> = ({ className, title, items, more = true }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <h4>{title}</h4>
      <ul>
        {items.map((item) => (
          <li key={item?.toString()}>
            <FontAwesomeIcon size='sm' icon={faChevronCircleRight as IconDefinition} className={styles.icon} /> {item}
          </li>
        ))}
        {ifElse(more, <li className={styles.more}>... and more!</li>)}
      </ul>
    </div>
  )
}

export default List
