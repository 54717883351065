import * as React from 'react'
import styles from './styles.module.scss'
import classNames from 'classnames'
import TwoColumn from '../../../../components/TwoColumn'
import Section from '../../../../components/Section'

type DracoBotCommand = {
  command: string
  description?: string
  minLevel?: number
}

type Props = {
  title: string
  commands?: DracoBotCommand[] | string[]
  className?: string
}

const minLevelBadge = (minLevel?: number) => {
  if (!minLevel || minLevel < 1) {
    return null
  }
  switch (minLevel) {
    case 1:
      return <div className={classNames(styles.level, styles.sub)}>Subscribers</div>
    case 2:
      return <div className={classNames(styles.level, styles.vip)}>VIPs</div>
    case 3:
      return <div className={classNames(styles.level, styles.mod)}>Moderators</div>
    case 4:
      return <div className={classNames(styles.level, styles.bo)}>Broadcaster</div>
    default:
      return null
  }
}

const CommandList: React.FC<Props> = ({ title, commands, className }) => {
  if (commands && commands.length && typeof commands[0] === 'string') {
    return (
      <Section title={title}>
        <div className={classNames(styles.list, className)}>
          <div className={styles.content}>
            {(commands as string[]).map((command) => (
              <div key={command}>{command}</div>
            ))}
          </div>
        </div>
      </Section>
    )
  }
  const hasDesc = (commands as DracoBotCommand[])?.some((command) => !!command.description)
  return commands && commands.length ? (
    <Section title={title}>
      <div className={classNames(styles.list, className)}>
        <div className={styles.content}>
          {(commands as DracoBotCommand[]).map((command) =>
            hasDesc ? (
              <TwoColumn key={command.command} lineitemLarge>
                <div>
                  !<b>{command.command}</b>
                  {minLevelBadge(command.minLevel)}
                </div>
                <div>{command.description}</div>
              </TwoColumn>
            ) : (
              <div key={command.command}>
                !<b>{command.command}</b>
                {minLevelBadge(command.minLevel)}
              </div>
            ),
          )}
        </div>
      </div>
    </Section>
  ) : null
}

export default CommandList
